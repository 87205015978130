.domain-name-row {
  @apply flex flex-col lg:flex-row flex-wrap justify-start items-start lg:space-x-4 mt-4;
}

.multiField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  .field {
    width: 100%;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.domain-name-row p {
  @apply text-red-500 text-sm italic pt-1;
}
